import React from 'react';
import Helmet from 'react-helmet';
import {navigate} from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import * as Ant from 'antd';
import 'antd/dist/antd.less';
import '../index.css';
import {Modal} from 'antd';

import * as AppContext from '../AppContext';
import {
  Member,
  CalendarOutlined,
  SettingsOutlined,
  Exit,
  SnippetsOutlined,
  FolderOpenOutlined,
  ProjectOutlined,
} from '../Widgets/Icon';
import MemberNotificationsDetail from './MemberNotificationDetail';
import ActivityIndicator from './ActivityIndicator';
import Toast from './Toast';

function MenuItem(props) {
  const {selected, onClick, iconGray, iconOrange} = props;
  return (
    <MenuItemWrapper selected={selected} onClick={onClick}>
      {selected ? iconOrange : iconGray}
      <div>{props.children}</div>
    </MenuItemWrapper>
  );
}

const MenuItemWrapper = styled.button`
  margin: 10px;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;

  background-color: ${(props) =>
    props.selected ? 'rgba(225,129,53,0.10)' : 'transparent'};

  width: 180px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  & > div {
    margin-left: 10px;
    text-align: left;
    font-size: 16px;
    color: ${(props) => (props.selected ? '#E18135' : '#ccc')};
  }

  :focus {
    outline: none;
  }
`;

const Layout = (props) => {
  const app = React.useContext(AppContext.Context);
  const {children, location, pageContext} = props;
  const isStaff = pageContext.prj === 'staff';
  let showSider = true;

  if (
    location.pathname === '/' ||
    location.pathname.indexOf('/login') === 0 ||
    location.pathname.indexOf('/public') === 0
  ) {
    showSider = false;
  }

  function onStaffLogout() {
    app.actions.setLoading(true);
    app.actions.staffLogout();
    navigate('/');
    app.actions.setLoading(false);
  }

  function onMemberLogout() {
    app.actions.setLoading(true);
    app.actions.memberLogout();
    navigate('/login');
    app.actions.setLoading(false);
  }

  const getMenuProps = (path, iconGray, iconOrange, subPath = '') => {
    return {
      selected: path === location.pathname || subPath === location.pathname,
      onClick: () => navigate(path),
      iconGray,
      iconOrange,
    };
  };

  React.useEffect(() => {
    async function staffAutoLogin() {
      try {
        await app.actions.staffAutoLogin();
      } catch (err) {
        console.warn('staff auto login failed', err);
        navigate('/');
      }
    }

    async function memberAutoLogin() {
      try {
        await app.actions.memberAutoLogin();
      } catch (err) {
        console.warn('member auto login failed', err);

        if (location.pathname === '/') {
          navigate('/');
        } else {
          navigate('/login');
        }
      }
    }

    isStaff ? staffAutoLogin() : memberAutoLogin();
  }, [app.actions, isStaff, location.pathname]);

  return (
    <>
      <Helmet>
        <title>人才天下會員管理系統</title>
        <link rel="canonical" href={`/images/favicon.png`} />
        <script
          src="https://apis.google.com/js/platform.js"
          async
          defer></script>
        <meta
          name="google-signin-client_id"
          content="213252574253-ciij4fcklhr0nsk784irk7k2koua52cv.apps.googleusercontent.com"></meta>
      </Helmet>

      {showSider ? (
        <Ant.Layout>
          <Ant.Layout.Sider
            theme="light"
            style={{
              overflow: 'auto',
              height: '100vh',
              position: 'fixed',
              boxShadow:
                '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
              left: 0,
            }}>
            {!isStaff && (
              <>
                <img
                  alt="logo"
                  src={'/images/logo.png'}
                  style={{width: '100%', height: 'auto', marginBottom: 40}}
                />

                <MenuItem
                  {...getMenuProps(
                    '/member',
                    <Member color={'#808080'} />,
                    <Member color={'#E18135'} />,
                  )}>
                  我的履歷
                </MenuItem>

                <MenuItem
                  {...getMenuProps(
                    '/activity',
                    <SnippetsOutlined color={'#808080'} />,
                    <SnippetsOutlined color={'#E18135'} />,
                  )}>
                  活動列表
                </MenuItem>

                <MenuItem
                  {...getMenuProps(
                    '/signup',
                    <CalendarOutlined color={'#808080'} />,
                    <CalendarOutlined color={'#E18135'} />,
                    '/event',
                  )}>
                  我的報名
                </MenuItem>

                <MenuItem
                  {...getMenuProps(
                    '/settings',
                    <SettingsOutlined color={'#808080'} />,
                    <SettingsOutlined color={'#E18135'} />,
                  )}>
                  修改密碼
                </MenuItem>
              </>
            )}

            {isStaff && (
              <>
                <img
                  alt="logo"
                  src={'/images/logo.png'}
                  style={{width: '100%', height: 'auto', marginBottom: 40}}
                />

                <MenuItem
                  {...getMenuProps(
                    '/member',
                    <Member color={'#808080'} />,
                    <Member color={'#E18135'} />,
                  )}>
                  會員管理
                </MenuItem>

                <MenuItem
                  {...getMenuProps(
                    '/activity',
                    <SnippetsOutlined color={'#808080'} />,
                    <SnippetsOutlined color={'#E18135'} />,
                  )}>
                  活動管理
                </MenuItem>

                <MenuItem
                  {...getMenuProps(
                    '/signup',
                    <ProjectOutlined color={'#808080'} />,
                    <ProjectOutlined color={'#E18135'} />,
                  )}>
                  報名管理
                </MenuItem>

                <MenuItem
                  {...getMenuProps(
                    '/project',
                    <FolderOpenOutlined color={'#808080'} />,
                    <FolderOpenOutlined color={'#E18135'} />,
                  )}>
                  專案管理
                </MenuItem>

                <MenuItem
                  {...getMenuProps(
                    '/settings',
                    <SettingsOutlined color={'#808080'} />,
                    <SettingsOutlined color={'#E18135'} />,
                  )}>
                  帳戶設定
                </MenuItem>
              </>
            )}

            <div
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                padding: 30,
                width: 200,
                display: 'flex',
                flexDirection: 'column',
              }}>
              <Ant.Button
                type="text"
                icon={
                  <span style={{marginRight: 10}}>
                    <Exit size={14} />
                  </span>
                }
                style={{
                  marginBottom: 15,
                  width: 150,
                  height: 50,
                  boxShadow:
                    '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
                }}
                onClick={
                  isStaff ? () => onStaffLogout() : () => onMemberLogout()
                }>
                登出
              </Ant.Button>

              <p
                style={{
                  textAlign: 'center',
                  fontSize: 14,
                  color: '#808080',
                  marginBottom: 0,
                }}>
                帳號：
                {isStaff
                  ? app.state.staff?.username
                  : app.state.member?.id_number}
              </p>
            </div>
          </Ant.Layout.Sider>

          <Ant.Layout style={{marginLeft: 200, backgroundColor: '#ffffff'}}>
            {isStaff
              ? app.state.staff
                ? children
                : null
              : app.state.member
              ? children
              : null}
          </Ant.Layout>
        </Ant.Layout>
      ) : (
        <main>{children}</main>
      )}

      <Toast />
      <ActivityIndicator />

      <Modal
        title={null}
        footer={null}
        bodyStyle={{padding: 0}}
        width={500}
        visible={!!app.state.memberNotifications}
        onCancel={() => {
          app.actions.setMemberNotifications(null);
        }}>
        <MemberNotificationsDetail
          records={app.state.memberNotifications}
          onCancel={() => {
            app.actions.setMemberNotifications(null);
          }}
          onOk={() => {
            app.actions.setMemberNotifications(null);
          }}
        />
      </Modal>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
