const mockData = require('./ApiMockData');
const data = require('../data.json');

const apiHost = data.apiHost;
const authHost = data.authHost;
const jstoreHost = data.jstoreHost;
const storageHost = data.storageHost;

let mock = true;
let memberToken = '';
let memberRefresh = '';
let staffToken = '';
let staffRefresh = '';

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

function setMock(isMock) {
  mock = isMock;
}

function getMock() {
  return mock;
}

function setMemberToken(t) {
  memberToken = t;
}

function setMemberRefresh(r) {
  memberRefresh = r;
}

function setStaffToken(t) {
  staffToken = t;
}

function setStaffRefresh(r) {
  staffRefresh = r;
}

function handleResp(resp) {
  if (resp.status.toString()[0] !== '2') {
    console.warn('Error Status Code', resp.status);
    throw resp;
  }

  if (resp.status.toString() === '204') {
    //status code 204: no content
    return {};
  }

  return resp.json();
}

//user
async function memberRegister({username, password}) {
  if (mock) {
    await delay(1000);
    return mockData.user.register;
  }

  const resp = await fetch(`${apiHost}/user/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      password,
    }),
  });
  const json = await handleResp(resp);
  return json;
}

async function memberRegisterByFacebook({token, identity}) {
  if (mock) {
    await delay(1000);
    return mockData.user.registerByFacebook;
  }

  const resp = await fetch(`${apiHost}/user/facebook/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token,
      identity,
    }),
  });
  const json = await handleResp(resp);
  return json;
}

async function memberRegisterByGoogle({token, identity}) {
  if (mock) {
    await delay(1000);
    return mockData.user.registerByGoogle;
  }

  const resp = await fetch(`${apiHost}/user/google/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token,
      identity,
    }),
  });
  const json = await handleResp(resp);
  return json;
}

async function memberLogin({username, password}) {
  if (mock) {
    await delay(1000);
    return mockData.user.login;
  }

  const resp = await fetch(`${apiHost}/user/sign-in`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      password,
    }),
  });
  const json = await handleResp(resp);
  return json;
}

async function memberLoginByFacebook({token}) {
  if (mock) {
    await delay(1000);
    return mockData.user.loginByFacebook;
  }

  const resp = await fetch(`${apiHost}/user/facebook/sign-in`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token,
    }),
  });
  const json = await handleResp(resp);
  return json;
}

async function memberLoginByGoogle({token}) {
  if (mock) {
    await delay(1000);
    return mockData.user.loginByGoogle;
  }

  const resp = await fetch(`${apiHost}/user/google/sign-in`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token,
    }),
  });
  const json = await handleResp(resp);
  return json;
}

async function memberRefreshToken() {
  if (mock) {
    await delay(1000);
    return mockData.user.refresh;
  }

  const resp = await fetch(
    `${authHost}/jwt/access?refresh_token=${memberRefresh}`,
    {
      method: 'GET',
    },
  );

  const json = await handleResp(resp);
  return json;
}

async function memberResetPassword({password, newPassword}) {
  if (mock) {
    await delay(1000);
    return mockData.user.resetPassword;
  }

  const resp = await fetch(
    `${apiHost}/user/reset_password?token=${memberToken}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        old_password: password,
        new_password: newPassword,
      }),
    },
  );

  const json = await handleResp(resp);
  return json;
}

async function memberGetProfile() {
  if (mock) {
    await delay(1000);
    return mockData.user.profile;
  }

  const resp = await fetch(`${apiHost}/profile?token=${memberToken}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const json = await handleResp(resp);
  return json;
}

async function memberUpdateProfile(data) {
  if (mock) {
    await delay(1000);
    mockData.user.profile = {...mockData.user.profile, ...data};
    return mockData.user.profile;
  }

  const resp = await fetch(`${apiHost}/profile/update?token=${memberToken}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });

  const json = await handleResp(resp);
  return json;
}

async function memberCreateParticipant({data, member}) {
  if (mock) {
    await delay(1000);
    //FIX ME: need reference api response
    return '';
  }

  const resp = await fetch(
    `${apiHost}/participant/create?token=${memberToken}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: member.owner,
        activity: data.id,
        duration: data.duration,
      }),
    },
  );

  const json = await handleResp(resp);
  return json;
}

async function memberGetParticipantList() {
  if (mock) {
    await delay(1000);
    //FIX ME: need reference api response
    return '';
  }

  const resp = await fetch(`${apiHost}/participant?token=${memberToken}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const json = await handleResp(resp);
  return json;
}

async function memberGetParticipantListByActivityId(id) {
  if (mock) {
    await delay(1000);
    //FIX ME: need reference api response
    return '';
  }

  const resp = await fetch(
    `${apiHost}/participant?activity_id=${id}&token=${memberToken}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  const json = await handleResp(resp);
  return json;
}

async function memberUpdateParticipantById(id, data) {
  if (mock) {
    await delay(1000);
    //FIX ME: need reference api response
    return;
  }

  const resp = await fetch(
    `${apiHost}/participant/${id}/confirm?token=${memberToken}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...data,
      }),
    },
  );

  const json = await handleResp(resp);
  return json;
}

async function memberGetActivitiesDetilByIds(ids) {
  if (mock) {
    await delay(1000);
    //FIX ME: need reference api response
    return '';
  }

  const resp = await fetch(
    `${jstoreHost}/document/activity/find?token=${memberToken}
  `,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: {
          id: {
            $in: ids,
          },
        },
      }),
    },
  );

  const json = await handleResp(resp);
  return json;
}

async function memberForgetPasswordByEmail(username) {
  if (mock) {
    await delay(1000);
    return mockData.user.profile.email;
  }

  const resp = await fetch(`${apiHost}/user/forget_password/email`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
    }),
  });

  const json = await handleResp(resp);
  return json;
}

async function memberForgetPassword(new_password) {
  if (mock) {
    await delay(1000);
    return;
  }

  const resp = await fetch(
    `${apiHost}/user/forget_password?token=${memberToken}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        new_password,
      }),
    },
  );

  const json = await handleResp(resp);
  return json;
}

//staff
async function staffLogin({username, password}) {
  if (mock) {
    console.log('mocking staffLogin');
    await delay(1000);
    return mockData.staff.login;
  }

  const resp = await fetch(`${apiHost}/admin/sign-in`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
      password,
    }),
  });

  const json = await handleResp(resp);
  return json;
}

async function staffRefreshToken() {
  if (mock) {
    await delay(1000);
    return mockData.staff.refresh;
  }

  const resp = await fetch(
    `${authHost}/jwt/access?refresh_token=${staffRefresh}`,
    {
      method: 'GET',
    },
  );

  const json = await handleResp(resp);
  return json;
}

async function staffResetPassword({password, newPassword}) {
  if (mock) {
    await delay(1000);
    return mockData.staff.resetPassword;
  }

  const resp = await fetch(
    `${apiHost}/admin/reset_password?token=${staffToken}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        old_password: password,
        new_password: newPassword,
      }),
    },
  );

  const json = await handleResp(resp);
  return json;
}

async function staffGetProfile() {
  if (mock) {
    await delay(1000);
    return mockData.staff.profile;
  }

  const resp = await fetch(`${apiHost}/admin/profile?token=${staffToken}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const json = await handleResp(resp);
  return json;
}

async function staffGetMemberList() {
  if (mock) {
    await delay(1000);
    return mockData.staff.memberList;
  }

  const resp = await fetch(
    `${jstoreHost}/document/user_profile/find?token=${staffToken}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: {},
      }),
    },
  );

  const json = await handleResp(resp);
  return json;
}

async function staffGetMemberDetailById(id) {
  if (mock) {
    await delay(1000);
    return mockData.memberDetail;
  }

  const resp = await fetch(
    `${jstoreHost}/document/user_profile/find-one?token=${staffToken}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: {
          owner: id,
        },
      }),
    },
  );
}

async function staffUpdateMemberDetailById(id, data) {
  if (mock) {
    await delay(1000);
    mockData.memberDetail = {...mockData.memberDetail, ...data};
    return mockData.staff.memberDetail;
  }

  const resp = await fetch(
    `${jstoreHost}/document/user_profile/update?token=${staffToken}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: {
          owner: id,
        },
        data: {
          ...data,
        },
      }),
    },
  );

  const json = await handleResp(resp);
  return json;
}

async function staffSendInterviewEmailById(id, title, content) {
  if (mock) {
    await delay(1000);
    return true;
  }

  const resp = await fetch(
    `${apiHost}/send/mail/interview?token=${staffToken}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        interviewee: id,
        title,
        message: content,
      }),
    },
  );

  const json = await handleResp(resp);
  return json;
}

async function staffSendManualEmailById(id, title, content) {
  if (mock) {
    await delay(1000);
    return true;
  }

  const resp = await fetch(`${apiHost}/send/mail/manual?token=${staffToken}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      interviewee: id,
      title,
      message: content,
    }),
  });

  const json = await handleResp(resp);
  return json;
}

async function staffSearchMemberByFields(fields) {
  if (mock) {
    await delay(1000);
    return true;
  }

  let query = {};

  if (fields.STAFF_LABELS.length > 0) {
    const nextFields = {...fields};
    delete nextFields.STAFF_LABELS;
    const STAFF_LABELS = fields.STAFF_LABELS.map((label) => ({
      STAFF_LABELS: label,
    }));

    query = {
      ...nextFields,
      $and: STAFF_LABELS,
    };
  } else {
    const nextFields = {...fields};
    delete nextFields.STAFF_LABELS;

    query = {
      ...nextFields,
    };
  }

  const resp = await fetch(
    `${jstoreHost}/document/user_profile/find?token=${staffToken}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: query,
      }),
    },
  );

  const json = await handleResp(resp);
  return json;
}

async function staffGetActivityList() {
  if (mock) {
    await delay(1000);
    return mockData.staff.activityList;
  }

  const resp = await fetch(
    `${jstoreHost}/document/activity/find?client_id=hwh`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({query: {}}),
    },
  );

  const json = await handleResp(resp);
  return json;
}

async function staffCreateActivity(data) {
  if (mock) {
    await delay(1000);
    return mockData.staff.activityDetail;
  }

  const resp = await fetch(
    `${jstoreHost}/document/activity/create?token=${staffToken}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({data}),
    },
  );

  const json = await handleResp(resp);
  return json;
}

async function staffUpdateActivityDetailById(id, data) {
  if (mock) {
    await delay(1000);
    mockData.staff.activityDetail = {...mockData.staff.activityDetail, ...data};
    return mockData.staff.activityDetail;
  }

  const resp = await fetch(
    `${jstoreHost}/document/activity/update?token=${staffToken}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: {
          id,
        },
        data: {
          ...data,
        },
      }),
    },
  );

  const json = await handleResp(resp);
  return json;
}

async function staffDeleteActivityDetailById(id) {
  if (mock) {
    await delay(1000);
    return {};
  }

  const resp = await fetch(
    `${jstoreHost}/document/activity/find-one/delete?token=${staffToken}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: {id},
      }),
    },
  );

  const json = await handleResp(resp);
  return json;
}

async function staffGetParticipantListByUserId(id) {
  if (mock) {
    await delay(1000);
    return;
  }

  const resp = await fetch(
    `${jstoreHost}/document/participant/find?token=${staffToken}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: {user: id},
      }),
    },
  );

  const json = await handleResp(resp);
  return json;
}

async function staffGetActivityListByIds(ids) {
  if (mock) {
    await delay(1000);
    return;
  }

  const resp = await fetch(
    `${jstoreHost}/document/activity/find?client_id=hwh`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: {
          id: {$in: ids},
        },
      }),
    },
  );

  const json = await handleResp(resp);
  return json;
}

async function staffGetParticipantListByActivityId(id) {
  if (mock) {
    await delay(1000);
    return;
  }

  const resp = await fetch(
    `${jstoreHost}/document/participant/find?token=${staffToken}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: {activity: id},
      }),
    },
  );

  const json = await handleResp(resp);
  return json;
}

async function staffGetParticipantProfiles(owners) {
  if (mock) {
    await delay(1000);
    return;
  }

  const resp = await fetch(
    `${jstoreHost}/document/user_profile/find?token=${staffToken}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: {
          owner: {$in: owners},
        },
      }),
    },
  );

  const json = await handleResp(resp);
  return json;
}

async function staffUpdateParticipantById(id, data) {
  if (mock) {
    await delay(1000);
    return;
  }

  const resp = await fetch(
    `${apiHost}/participant/${id}/update?token=${staffToken}`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...data,
      }),
    },
  );

  const json = await handleResp(resp);
  return json;
}

async function staffSendParticipantEmailById(id, title, content) {
  if (mock) {
    await delay(1000);
    return;
  }

  const resp = await fetch(
    `${apiHost}/send/mail/participant?token=${staffToken}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        participant_id: id,
        title,
        message: content,
      }),
    },
  );

  const json = await handleResp(resp);
  return json;
}

async function staffExportMembersCSV() {
  if (mock) {
    await delay(1000);
    return;
  }

  const resp = await fetch(`${apiHost}/admin/export_csv?token=${staffToken}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const blob = await resp.blob();
  const url = window.URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

async function staffForgetPasswordByEmail(username) {
  if (mock) {
    await delay(1000);
    return mockData.staff.profile.email;
  }

  const resp = await fetch(`${apiHost}/admin/forget_password/email`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      username,
    }),
  });

  const json = await handleResp(resp);
  return json;
}

async function staffForgetPassword(new_password) {
  if (mock) {
    await delay(1000);
    return;
  }

  const resp = await fetch(
    `${apiHost}/admin/forget_password?token=${staffToken}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        new_password,
      }),
    },
  );

  const json = await handleResp(resp);
  return json;
}

async function staffGetProjectList() {
  await delay(1000);
  return mockData.staff.projectList;
}

async function staffCreateProject(data) {
  await delay(1000);
  const nextProject = {
    ...data,
    id: Math.floor(Math.random() * 10000000000).toString(),
  };
  mockData.staff.projectList.push(nextProject);

  return nextProject;
}

async function staffGetProjectById(id) {
  await delay(1000);
  return mockData.staff.projectList.find((project) => project.id === id);
}

async function staffUpdateProjectDetailById(id, data) {
  await delay(1000);
  const idx = mockData.staff.projectList.findIndex((prj) => prj.id === id);
  if (idx === -1) {
    throw new Error(`staffUpdateProject: no such project with id ${id}`);
  }
  const nextProject = {...data};
  mockData.staff.projectList[idx] = nextProject;
  return nextProject;
}

async function staffGetHumanRuleVersions() {
  await delay(1000);
  return mockData.staff.humanRuleVersions;
}

async function staffGetHumanRuleRecords({project, version}) {
  await delay(1000);
  return mockData.staff.humanRuleRecords.filter(
    (r) => r.project === project && r.version === version,
  );
}

//common
async function getCountryTownByCode(code) {
  const resp = await fetch(
    `https://daiwanlang.netlify.app/api/行政區/郵遞區號/${code}/get`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  );

  const json = await handleResp(resp);
  return json;
}

async function getActivityList() {
  if (mock) {
    await delay(1000);
    return mockData.user.activityList;
  }

  const resp = await fetch(
    `${jstoreHost}/document/activity/find?client_id=hwh`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: {},
      }),
    },
  );

  const json = await handleResp(resp);
  return json;
}

async function getActivityById(id) {
  if (mock) {
    await delay(1000);
    return mockData.user.activityList.find((record) => record.id === id);
  }

  const resp = await fetch(
    `${jstoreHost}/document/activity/find-one?client_id=hwh`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: {
          id,
        },
      }),
    },
  );

  const json = await handleResp(resp);
  return json;
}

async function createImageRequest(file) {
  if (mock) {
    await delay(1000);
    return '';
  }

  const resp = await fetch(
    `${storageHost}/storage/presigned/url?token=${memberToken}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        acl: 'public-read',
        'Content-Type': file.type,
        key: file.name,
      }),
    },
  );

  const json = await handleResp(resp);
  return json;
}

async function staffCreateImageRequest(file) {
  if (mock) {
    await delay(1000);
    return '';
  }

  const resp = await fetch(
    `${storageHost}/storage/presigned/url?token=${staffToken}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        acl: 'public-read',
        'Content-Type': file.type,
        key: file.name,
      }),
    },
  );

  const json = await handleResp(resp);
  return json;
}

module.exports = {
  setMock,
  getMock,
  //member
  setMemberToken,
  setMemberRefresh,
  memberRegister,
  memberRegisterByFacebook,
  memberRegisterByGoogle,
  memberLogin,
  memberLoginByFacebook,
  memberLoginByGoogle,
  memberRefreshToken,
  memberResetPassword,
  memberGetProfile,
  memberUpdateProfile,
  memberCreateParticipant,
  memberGetParticipantList,
  memberGetParticipantListByActivityId,
  memberUpdateParticipantById,
  memberGetActivitiesDetilByIds,
  memberForgetPasswordByEmail,
  memberForgetPassword,
  //staff
  setStaffToken,
  setStaffRefresh,
  staffLogin,
  staffRefreshToken,
  staffResetPassword,
  staffGetProfile,
  staffGetMemberList,
  staffUpdateMemberDetailById,
  staffSendInterviewEmailById,
  staffSendManualEmailById,
  staffSearchMemberByFields,
  staffGetActivityList,
  staffCreateActivity,
  staffUpdateActivityDetailById,
  staffDeleteActivityDetailById,
  staffGetParticipantListByUserId,
  staffGetActivityListByIds,
  staffGetParticipantListByActivityId,
  staffUpdateParticipantById,
  staffGetParticipantProfiles,
  staffSendParticipantEmailById,
  staffExportMembersCSV,
  staffForgetPasswordByEmail,
  staffForgetPassword,
  staffCreateImageRequest,
  staffGetProjectList,
  staffCreateProject,
  staffGetProjectById,
  staffUpdateProjectDetailById,
  staffGetHumanRuleVersions,
  staffGetHumanRuleRecords,
  //common
  getCountryTownByCode,
  getActivityList,
  getActivityById,
  createImageRequest,
};
