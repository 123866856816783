import React from 'react';
const Api = require('./Api');
const config = require('../data.json');

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const Context = React.createContext();

Api.setMock(false);

const mockProjectList = [
  {
    duration: [
      {
        date: '2021-04-01',
        time: '',
        startTime: '12:00',
        endTime: '16:00',
      },
      {
        date: '2021-04-01',
        time: '',
        startTime: '16:00',
        endTime: '19:00',
      },
      {
        date: '2021-04-02',
        time: '',
        startTime: '12:00',
        endTime: '19:00',
      },
    ],
    name: '2021急診醫學會年會',
    budget: 500000,
    id: '2920601376',
    created: 1616144170,
    updated: 1618474657,
  },
];
const mockHumanRuleVersions = [
  {
    version: 'v1',
    created: 1616144170 - 86400 * 3,
    updated: 1618474657 - 86400 * 2,
  },
  {version: 'v2', created: 1616144170 - 86400 * 2, updated: 1618474657 - 86400},
  {version: 'v3', created: 1616144170 - 86400, updated: 1618474657},
];
const mockHumanRuleRecords = [
  {
    id: '1',
    project: '2920601376',
    version: 'v1',
    name: 'test record 1',
  },
  {
    id: '2',
    project: '2920601376',
    version: 'v1',
    name: 'test record 2',
  },
];

class Provider extends React.Component {
  constructor(props) {
    super(props);
    console.log('App initialization');
    this.state = {
      config,
      loading: false,
      toastContent: null,
      staff: null,
      member: null,
      memberNotifications: null,
    };
    this.actions = {
      setLoading: (loading) => this.setState({loading}),
      setToast: (toastContent) => this.setState({toastContent}),

      //user
      memberRegister: async ({username, password}) => {
        try {
          const result = await Api.memberRegister({username, password});
          return result;
        } catch (err) {
          throw err;
        }
      },

      memberRegisterByFacebook: async ({token, identity}) => {
        try {
          const result = await Api.memberRegisterByFacebook({token, identity});
          return result;
        } catch (err) {
          throw err;
        }
      },

      memberRegisterByGoogle: async ({token, identity}) => {
        try {
          const result = await Api.memberRegisterByGoogle({token, identity});
          return result;
        } catch (err) {
          throw err;
        }
      },

      memberLogin: async ({username, password}) => {
        try {
          let result = await Api.memberLogin({username, password});

          Api.setMemberToken(result.token);

          window.localStorage.setItem('username', username);
          window.localStorage.setItem('token', result.token);
          window.localStorage.setItem('refresh', result.refresh);

          let member = await Api.memberGetProfile();
          this.setState({member: {username, ...member}});

          if (!member.id_number) {
            let data = {id_number: username};
            await Api.memberUpdateProfile(data);
          }

          return member;
        } catch (err) {
          throw err;
        }
      },

      memberLoginByFacebook: async ({token}) => {
        try {
          const result = await Api.memberLoginByFacebook({token});

          Api.setMemberToken(result.token);

          window.localStorage.setItem('token', result.token);
          window.localStorage.setItem('refresh', result.refresh);

          let member = await Api.memberGetProfile();
          this.setState({member});

          if (!member.id_number) {
            let data = {id_number: member.username};
            await Api.memberUpdateProfile(data);
          }

          return member;
        } catch (err) {
          throw err;
        }
      },

      memberLoginByGoogle: async ({token}) => {
        try {
          const result = await Api.memberLoginByGoogle({token});

          Api.setMemberToken(result.token);

          window.localStorage.setItem('token', result.token);
          window.localStorage.setItem('refresh', result.refresh);

          let member = await Api.memberGetProfile();
          this.setState({member});

          if (!member.id_number) {
            let data = {id_number: member.username};
            await Api.memberUpdateProfile(data);
          }

          return member;
        } catch (err) {
          throw err;
        }
      },

      memberAutoLogin: async () => {
        let mock = Api.getMock();
        if (mock) {
          let member = await Api.memberGetProfile();
          this.setState({member});
          return;
        }

        try {
          let token = window && window.localStorage.getItem('token');

          if (token) {
            Api.setMemberToken(token);
            let member = await Api.memberGetProfile();
            let profile = window && window.localStorage.getItem('profile');

            if (!profile) {
              this.setState({member});
              return;
            }

            if (profile && member.updated) {
              profile = JSON.parse(profile);

              if (profile.updated > member.updated * 1000) {
                this.setState({member: profile});
              }
              return;
            }

            if (profile && member.created) {
              profile = JSON.parse(profile);

              if (profile.updated > member.created * 1000) {
                this.setState({member: profile});
              }

              return;
            }
          }
        } catch (err) {
          throw err;
        }
      },

      memberSetUsername: (boolean) => {
        window.localStorage.setItem('keep', boolean);
      },

      memberGetUsername: () => {
        if (typeof window !== 'undefined') {
          return window.localStorage.getItem('username');
        }
        return null;
      },

      memberLogout: () => {
        this.setState({staff: null});
        if (window.localStorage.getItem('keep') === 'true') {
          window.localStorage.removeItem('token');
          window.localStorage.removeItem('refresh');
        } else {
          window.localStorage.clear();
        }
      },

      memberResetPassword: async ({password, newPassword}) => {
        try {
          const result = await Api.memberResetPassword({password, newPassword});
          return result;
        } catch (err) {
          throw err;
        }
      },

      memberGetProfile: async () => {
        try {
          const result = await Api.memberGetProfile();
          return result;
        } catch (err) {
          throw err;
        }
      },

      memberUpdateProfile: async (values) => {
        try {
          delete values.username;
          const result = await Api.memberUpdateProfile(values);
          this.setState({member: result});
          return result;
        } catch (err) {
          throw err;
        }
      },

      memberCreateImageRequest: async (file) => {
        try {
          const result = await Api.createImageRequest(file);
          return result;
        } catch (err) {
          throw err;
        }
      },

      memberCreateParticipant: async (data) => {
        try {
          let {member} = this.state;
          const result = await Api.memberCreateParticipant({data, member});
          return result;
        } catch (err) {
          throw err;
        }
      },

      memberGetParticipantList: async () => {
        try {
          const result = await Api.memberGetParticipantList();
          return result;
        } catch (err) {
          throw err;
        }
      },

      memberGetParticipantListByActivityId: async (id) => {
        try {
          const result = await Api.memberGetParticipantListByActivityId(id);
          return result;
        } catch (err) {
          throw err;
        }
      },

      memberUpdateParticipantById: async (id, data) => {
        try {
          const result = await Api.memberUpdateParticipantById(id, data);
          return result;
        } catch (err) {
          throw err;
        }
      },

      memberGetActivitiesDetilByIds: async (ids) => {
        try {
          const result = await Api.memberGetActivitiesDetilByIds(ids);
          return result;
        } catch (err) {
          throw err;
        }
      },

      memberForgetPasswordByEmail: async (username) => {
        try {
          const result = await Api.memberForgetPasswordByEmail(username);
          return result;
        } catch (err) {
          throw err;
        }
      },

      memberForgetPassword: async (new_password) => {
        try {
          const result = await Api.memberForgetPassword(new_password);
          return result;
        } catch (err) {
          throw err;
        }
      },

      memberSetToken: async (token) => {
        window.localStorage.setItem('token', token);
      },

      //staff
      staffLogin: async ({username, password}) => {
        try {
          const result = await Api.staffLogin({username, password});
          Api.setStaffToken(result.token);

          window.localStorage.setItem('username', username);
          window.localStorage.setItem('token', result.token);
          window.localStorage.setItem('refresh', result.refresh);

          let staff = await Api.staffGetProfile();
          this.setState({staff: {username, ...staff}});

          return staff;
        } catch (err) {
          throw err;
        }
      },

      staffAutoLogin: async () => {
        try {
          let token = window && window.localStorage.getItem('token');

          if (token) {
            Api.setStaffToken(token);
            let staff = await Api.staffGetProfile();
            console.warn('first token', staff);
            this.setState({staff});
          }
        } catch (err) {
          let refresh = window && window.localStorage.getItem('refresh');
          if (refresh) {
            console.warn('auto login fail, and try refresh', err);
            let token = await Api.staffRefreshToken();
            Api.setStaffToken(token);
            let staff = await Api.staffGetProfile();

            console.warn('refresh token get profile', staff);
            this.setState({staff});
          } else {
            throw err;
          }
        }
      },

      staffSetUsername: (boolean) => {
        window.localStorage.setItem('keep', boolean);
      },

      staffGetUsername: () => {
        if (typeof window !== 'undefined') {
          return window.localStorage.getItem('username');
        }
        return null;
      },

      staffLogout: () => {
        this.setState({staff: null});
        if (window.localStorage.getItem('keep') === 'true') {
          window.localStorage.removeItem('token');
          window.localStorage.removeItem('refresh');
        } else {
          window.localStorage.clear();
        }
      },

      staffResetPassword: async ({password, newPassword}) => {
        try {
          const result = await Api.staffResetPassword({password, newPassword});

          return result;
        } catch (err) {
          throw err;
        }
      },

      staffGetProfile: async () => {
        try {
          const result = await Api.staffGetProfile();
          return result;
        } catch (err) {
          throw err;
        }
      },

      staffGetMemberList: async () => {
        try {
          const result = await Api.staffGetMemberList();
          return result;
        } catch (err) {
          throw err;
        }
      },

      staffUpdateMemberDetailById: async (id, data) => {
        try {
          const result = await Api.staffUpdateMemberDetailById(id, data);
          return result;
        } catch (err) {
          throw err;
        }
      },

      staffSendInterviewEmailById: async (id, title, content) => {
        try {
          const result = await Api.staffSendInterviewEmailById(
            id,
            title,
            content,
          );
          return result;
        } catch (err) {
          throw err;
        }
      },

      staffSendManualEmailById: async (id, title, content) => {
        try {
          const result = await Api.staffSendManualEmailById(id, title, content);
          return result;
        } catch (err) {
          throw err;
        }
      },

      staffSearchMemberByFields: async (fields) => {
        try {
          const result = await Api.staffSearchMemberByFields(fields);
          return result;
        } catch (err) {
          throw err;
        }
      },

      staffCreateActivity: async (data) => {
        try {
          const result = await Api.staffCreateActivity(data);
          return result;
        } catch (err) {
          throw err;
        }
      },

      staffUpdateActivityDetailById: async (id, data) => {
        try {
          const result = await Api.staffUpdateActivityDetailById(id, data);
          return result;
        } catch (err) {
          throw err;
        }
      },

      staffCreateActivityImageRequest: async (file) => {
        try {
          const result = await Api.staffCreateImageRequest(file);
          return result;
        } catch (err) {
          throw err;
        }
      },

      staffGetParticipantProfiles: async (owners) => {
        try {
          const result = await Api.staffGetParticipantProfiles(owners);
          return result;
        } catch (err) {
          throw err;
        }
      },

      staffUpdateParticipantById: async (id, data) => {
        try {
          const result = await Api.staffUpdateParticipantById(id, data);
          return result;
        } catch (err) {
          throw err;
        }
      },

      staffSendParticipantEmailById: async (id, title, content) => {
        try {
          const result = await Api.staffSendParticipantEmailById(
            id,
            title,
            content,
          );
          return result;
        } catch (err) {
          throw err;
        }
      },

      staffExportMembersCSV: async () => {
        try {
          let result = await Api.staffExportMembersCSV();
        } catch (err) {
          throw err;
        }
      },

      staffGetParticipantListByUserId: async (id) => {
        try {
          let result = await Api.staffGetParticipantListByUserId(id);
          return result;
        } catch (err) {
          throw err;
        }
      },

      staffGetActivityListByIds: async (ids) => {
        try {
          let result = await Api.staffGetActivityListByIds(ids);
          return result;
        } catch (err) {
          throw err;
        }
      },

      staffGetParticipantListByActivityId: async (id) => {
        try {
          const result = await Api.staffGetParticipantListByActivityId(id);
          return result;
        } catch (err) {
          throw err;
        }
      },

      staffForgetPasswordByEmail: async (username) => {
        try {
          const result = await Api.staffForgetPasswordByEmail(username);
          return result;
        } catch (err) {
          throw err;
        }
      },

      staffForgetPassword: async (new_password) => {
        try {
          const result = await Api.staffForgetPassword(new_password);
          return result;
        } catch (err) {
          throw err;
        }
      },

      staffForgrtPasswordByPreparation: async (token, entries) => {
        window.localStorage.setItem('token', token);
        window.history.replaceState({}, document.title, entries.split('?')[0]);
      },

      staffGetProjectList: async () => {
        try {
          const result = await Api.staffGetProjectList();
          return result;
        } catch (err) {
          throw err;
        }
      },

      staffCreateProject: async (data) => {
        try {
          const result = await Api.staffCreateProject(data);
          return result;
        } catch (err) {
          throw err;
        }
      },

      staffGetProjectById: async (id) => {
        try {
          const result = await Api.staffGetProjectById(id);
          return result;
        } catch (err) {
          throw err;
        }
      },

      staffUpdateProjectDetailById: async (id, data) => {
        try {
          const result = await Api.staffUpdateProjectDetailById(id, data);
          return result;
        } catch (err) {
          throw err;
        }
      },

      staffGetHumanRuleVersions: async (id) => {
        try {
          const result = await Api.staffGetHumanRuleVersions(id);
          return result;
        } catch (err) {
          throw err;
        }
      },

      staffGetHumanRuleRecords: async ({project, version}) => {
        try {
          const result = await Api.staffGetHumanRuleRecords({project, version});
          return result;
        } catch (err) {
          throw err;
        }
      },

      staffSetToken: async (token) => {
        window.localStorage.setItem('token', token);
      },

      //common
      getCountryTownByCode: async (code) => {
        return Api.getCountryTownByCode(code);
      },

      fetchActivityList: async () => {
        return Api.getActivityList();
      },

      fetchActivityById: async (id) => {
        return Api.getActivityById(id);
      },

      //others, need to clean up
      setMemberNotifications: (memberNotifications) =>
        this.setState({memberNotifications}),

      fetchMemberNotifications: async () => {
        await delay(1000);
        let result = [
          {
            date: '2021/02/11',
            title: '2021台北國際電玩展已錄取，請前往我的日曆確認是否參加',
          },
          {
            date: '2021/02/12',
            title: '新活動開放報名：2021台北國際電玩展',
          },
          {
            date: '2021/02/13',
            title: '面試已通過，請補齊合約資料與銀行帳戶影本',
          },
        ];
        this.setState({memberNotifications: result});
      },
    };
  }

  render() {
    return (
      <Context.Provider
        value={{
          state: this.state,
          actions: this.actions,
        }}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export {Context, Provider};
