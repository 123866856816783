import React from 'react';
import * as AntIcon from '@ant-design/icons';

function Avatar({color = 'black', size = 16}) {
  return (
    <img
      alt="avatar"
      src="../images/avatar.png"
      style={{width: size, height: size}}
    />
  );
}

function LockOutlined({color = 'black', size = 16}) {
  return <AntIcon.LockOutlined style={{fontSize: size, color}} />;
}

function Facebook({color = 'black', size = 16}) {
  return (
    <img
      alt="facebook"
      src="/images/facebook.png"
      style={{width: size, height: size}}
    />
  );
}

function Google({color = 'black', size = 16}) {
  return (
    <img
      alt="google"
      src="/images/google.png"
      style={{width: size, height: size}}
    />
  );
}

function Member({color = 'black', size = 16}) {
  return <AntIcon.IdcardOutlined style={{fontSize: size, color}} />;
}

function CalendarOutlined({color = 'black', size = 16}) {
  return <AntIcon.CalendarOutlined style={{fontSize: size, color}} />;
}

function SettingsOutlined({color = 'black', size = 16}) {
  return <AntIcon.SettingOutlined style={{fontSize: size, color}} />;
}

function Exit({color = 'black', size = 16}) {
  return (
    <img
      alt="exit"
      src={'/images/exit.png'}
      style={{width: size, height: size}}
    />
  );
}

function Edit({color = 'black', size = 16}) {
  return <AntIcon.EditOutlined style={{fontSize: size, color}} />;
}

function BellOutlined({color = 'black', size = 16}) {
  return <AntIcon.BellOutlined style={{fontSize: size, color}} />;
}

function UserOutlined({color = 'black', size = 16}) {
  return <AntIcon.UserOutlined style={{fontSize: size, color}} />;
}

function LeftOutlined({color = 'black', size = 16}) {
  return <AntIcon.LeftOutlined style={{fontSize: size, color}} />;
}

function ClockCircleOutlined({color = 'black', size = 16}) {
  return <AntIcon.ClockCircleOutlined style={{fontSize: size, color}} />;
}

function EnvironmentOutlined({color = 'black', size = 16}) {
  return <AntIcon.EnvironmentOutlined style={{fontSize: size, color}} />;
}

function FolderOpenOutlined({color = 'black', size = 16}) {
  return <AntIcon.FolderOpenOutlined style={{fontSize: size, color}} />;
}

function SnippetsOutlined({color = 'black', size = 16}) {
  return <AntIcon.SnippetsOutlined style={{fontSize: size, color}} />;
}

function CommentSearch({color = 'black', size = 16}) {
  return (
    <img
      alt="comment-search"
      src="../images/comment-search.png"
      style={{width: size, height: size}}
    />
  );
}

function CameraFilled({color = 'balck', size = 16}) {
  return <AntIcon.CameraFilled style={{fontSize: size, color}} />;
}

function UploadOutlined({color = 'black', size = 16}) {
  return <AntIcon.UploadOutlined style={{fontSize: size, color}} />;
}

function MoneyCollectOutlined({color = 'black', size = 16}) {
  return <AntIcon.MoneyCollectOutlined style={{fontSize: size, color}} />;
}

function BlockOutlined({color = 'black', size = 16}) {
  return <AntIcon.BlockOutlined style={{fontSize: size, color}} />;
}

function HighlightOutlined({color = 'black', size = 16}) {
  return <AntIcon.HighlightOutlined style={{fontSize: size, color}} />;
}

function BookOutlined({color = 'black', size = 16}) {
  return <AntIcon.BookOutlined style={{fontSize: size, color}} />;
}

function ProjectOutlined({color = 'black', size = 16}) {
  return <AntIcon.ProjectOutlined style={{fontSize: size, color}} />;
}

function CheckCircleOutlined({color = 'black', size = 16}) {
  return <AntIcon.CheckCircleOutlined style={{fontSize: size, color}} />;
}

function CloseCircleOutlined({color = 'black', size = 16}) {
  return <AntIcon.CloseCircleOutlined style={{fontSize: size, color}} />;
}

function SyncOutlined({color = 'black', size = 16}) {
  return <AntIcon.SyncOutlined style={{fontSize: size, color}} spin />;
}

function MinusCircleOutlined({color = 'black', size = 16}) {
  return <AntIcon.MinusCircleOutlined style={{fontSize: size, color}} />;
}

function UnorderedListOutlined({color = 'black', size = 16}) {
  return <AntIcon.UnorderedListOutlined style={{fontSize: size, color}} />;
}

export {
  Avatar,
  LockOutlined,
  Facebook,
  Google,
  Member,
  CalendarOutlined,
  SettingsOutlined,
  Exit,
  Edit,
  BellOutlined,
  UserOutlined,
  LeftOutlined,
  ClockCircleOutlined,
  EnvironmentOutlined,
  FolderOpenOutlined,
  SnippetsOutlined,
  CommentSearch,
  CameraFilled,
  UploadOutlined,
  MoneyCollectOutlined,
  BlockOutlined,
  HighlightOutlined,
  BookOutlined,
  ProjectOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
  MinusCircleOutlined,
  UnorderedListOutlined,
};
