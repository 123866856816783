import React from 'react';
import styled from 'styled-components';
import {Button} from 'antd';

function MemberNotificationsDetail(props) {
  const {records, onCancel, onOk} = props;

  return (
    <Wrapper>
      <h3>所有通知</h3>

      {records.length > 0 ? (
        records.map((record, idx) => (
          <section>
            <div
              className="field"
              style={records.length === idx + 1 ? {borderBottom: 0} : {}}>
              <label>{record.date}</label>
              <p>{record.title}</p>
            </div>
          </section>
        ))
      ) : (
        <section>
          <div className="field" style={{borderBottom: 0}}>
            <label>目前沒有任何新的通知：）</label>
          </div>
        </section>
      )}

      <div className="action-bar">
        <Button
          type="outline"
          onClick={onCancel}
          style={{
            marginRight: 15,
            fontSize: 18,
            minWidth: 125,
            height: 40,
            boxShadow: '0 2px 10px 0 rgba(0,0,0,0.15)',
          }}>
          取消
        </Button>
        <Button
          type="primary"
          onClick={onOk}
          style={{
            fontSize: 18,
            minWidth: 125,
            height: 40,
            boxShadow: '0 2px 10px 0 rgba(0,0,0,0.15)',
          }}>
          閱讀完畢
        </Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & > h3 {
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 50px 50px 20px 50px;
    font-size: 24px;
    color: #4a4a4a;
    line-height: 22px;
  }

  & > section {
    margin: 5px 25px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > .field {
      border-bottom: 1px solid #cccccc;
      padding: 5px 25px;
      width: 100%;

      font-size: 18px;
      color: #4a4a4a;
      line-height: 22px;

      & > label {
      }

      & > p {
        margin-top: 5px;
      }
    }
  }

  & > .action-bar {
    border-top: 1px solid #cccccc;
    padding: 30px;

    display: flex;
    justify-content: flex-end;
  }
`;

export default MemberNotificationsDetail;
