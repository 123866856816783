// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-activity-mgr-staff-page-js": () => import("./../../../src/Templates/ActivityMgr/StaffPage.js" /* webpackChunkName: "component---src-templates-activity-mgr-staff-page-js" */),
  "component---src-templates-human-rule-editor-index-js": () => import("./../../../src/Templates/HumanRuleEditor/index.js" /* webpackChunkName: "component---src-templates-human-rule-editor-index-js" */),
  "component---src-templates-login-staff-page-js": () => import("./../../../src/Templates/Login/StaffPage.js" /* webpackChunkName: "component---src-templates-login-staff-page-js" */),
  "component---src-templates-member-mgr-staff-page-js": () => import("./../../../src/Templates/MemberMgr/StaffPage.js" /* webpackChunkName: "component---src-templates-member-mgr-staff-page-js" */),
  "component---src-templates-project-mgr-index-js": () => import("./../../../src/Templates/ProjectMgr/index.js" /* webpackChunkName: "component---src-templates-project-mgr-index-js" */),
  "component---src-templates-settings-mgr-staff-page-js": () => import("./../../../src/Templates/SettingsMgr/StaffPage.js" /* webpackChunkName: "component---src-templates-settings-mgr-staff-page-js" */),
  "component---src-templates-signup-mgr-staff-page-js": () => import("./../../../src/Templates/SignupMgr/StaffPage.js" /* webpackChunkName: "component---src-templates-signup-mgr-staff-page-js" */),
  "component---src-templates-test-page-js": () => import("./../../../src/Templates/TestPage.js" /* webpackChunkName: "component---src-templates-test-page-js" */)
}

