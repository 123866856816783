const moment = require('moment');

//user
const user = {
  registerByFacebook: {},
  registerByGoogle: {},
  register: {
    id: '1',
    pw_login: true,
    username: 'A282375641',
  },
  loginByFacebook: {},
  loginByGoogle: {},
  login: {
    refresh: 'refresh',
    token: 'token',
  },
  refresh: {},
  resetPassword: {},
  profile: {
    member_primary_id: '',
    name: '張曉曉',
    nationality: true,
    country: '',
    id_number: 'A282375641',
    gender: false,
    age: 18,
    birthday: moment('1990-01-01'),
    location: '台北、台中',
    disability_credentials: 'https://picsum.photos/seed/picsum/200/300',
    avatar: 'https://picsum.photos/200/300',
    disability: false,
    permanent_address_code: '104',
    permanent_address_city: '台北市',
    permanent_address_detail: '民權西路136號10樓之5',
    residence_address_code: '100',
    residence_address_city: '台北市',
    residence_address_detail: '民權西路136號9樓之3',
    phone_home: '02-2557-8895',
    phone: '0919000000',
    email: 'yahan.chang@revteltech.com',
    line: 'line_test',
    height: 50,
    weight: 168,
    military_service_state: '免服',
    driver_license: '機車',
    notifiable_disease: false,
    highest_education_level: '碩士',
    highest_education_state: true,
    highest_education_name: '國立台灣體系大學',
    highest_education_department: '教育休閒系',
    highest_education_grade: 3,
    education_experience: '寫些學校經驗',
    emergency_contact: '張爸爸',
    emergency_contact_relationship: '父女',
    emergency_contact_phone: '0918000000',
    family_member_1_name: '張妹妹',
    family_member_1_contact: '0917000000',
    family_member_2_name: '張弟弟',
    family_member_2_contact: '0916000000',
    company_1_name: '全家便利商店',
    company_1_position: '蒸地瓜店員',
    company_1_duration: [moment('2020-01'), moment('2020-12')],
    company_2_name: '九大文具店',
    company_2_position: '採購店員',
    company_2_duration: [moment('2019-01'), moment('2019-12')],
    company_3_name: '五十嵐手搖飲',
    company_3_position: '紅茶沖泡店員',
    company_3_duration: [moment('2018-01'), moment('2018-12')],
    company_note: '還做過很多工作 ex.早餐店、行銷專員',
    language_english: '略懂',
    language_taiwanese: '普通',
    language_japanese: '中上',
    language_spanish: '精通',
    language_korean: '無',
    language_german: '略懂',
    language_french: '無',
    language_thai: '略懂',
    language_vietnamese: '中上',
    language_malay: '精通',
    language_other_name: '土耳其語',
    language_other_level: '略懂',
    language_note: '還會非洲語、馬來西亞語',
    ability_computer: true,
    ability_computer_name: '網頁、app 開發',
    ability_chinese_typewriting: true,
    ability_chinese_typewriting_name: '中打證照初級',
    ability_english_typewriting: true,
    ability_english_typewriting_name: '英打證照中級',
    ability_english_vertification: false,
    ability_english_vertification_name: 'GEPT',
    ability_english_vertification_grade: '500',
    ability_computer_file: 'https://picsum.photos/200/300',
    ability_chinese_typewriting_file: 'https://picsum.photos/200/300',
    ability_english_typewriting_file: 'https://picsum.photos/200/300',
    ability_english_vertification_file: 'https://picsum.photos/200/300',
    expertise: '玩扯鈴、彈古琴',
    study_aboard: true,
    study_aboard_time: 0,
    study_aboard_country: '非洲',
    study_aboard_major: '精銳部隊學習',
    study_aboard_file: 'https://picsum.photos/200/300',
    recent_status: '待業中',
    recent_status_others: '請雇用我（鞠躬）',
    diet: true,
    diet_note: '不挑食，什麼都可以吃',
    formal_wear: true,
    formal_wear_note: '有很多黑色衣服',
    source: '打工趣',
    source_note: 'hihi',
    bank_account: '00000001293388',
    post_office_number: '0001933',
    post_office_account: '0001283031111',
    bank_code: '822',
    bank_branch_code: '1254',
    bank_account_note: '中國信託-信義分行',
  },
  activityList: [
    {
      id: 1,
      name: '2021智慧城市展',
      date_start: '2021-03-23',
      date_end: '2021-03-26',
      address_name: '台北南港展覽館2館',
      address: '臺北市信義路五段五號',
      status: 'publish',
      description:
        '因應疫情新常態，今年智慧城市論壇暨展覽改以線上方式持續推動國際間智慧城市的交流。未來線上展每半年為一個展覽檔期，上半年將結合每年3月份舉辦的智慧城市展，成為虛實整合的展會，讓台灣的智慧城市解決方案不受到COVID-19的影響，可不間斷地與全球接軌 ，並為Smart City Online參展廠商創造更多國際商務機會。人才天下誠摯招募所有參與過智慧城市展的工作人員，也歡迎新鮮人或學生打工前來報名喔！',
      content:
        '因應疫情新常態，今年智慧城市論壇暨展覽改以線上方式持續推動國際間智慧城市的交流。未來線上展每半年為一個展覽檔期，上半年將結合每年3月份舉辦的智慧城市展，成為虛實整合的展會，讓台灣的智慧城市解決方案不受到COVID-19的影響，可不間斷地與全球接軌 ，並為Smart City Online參展廠商創造更多國際商務機會。人才天下誠摯招募所有參與過智慧城市展的工作人員，也歡迎新鮮人或學生打工前來報名喔！content',
      image: 'https://picsum.photos/seed/picsum/200/300',
      duration: [
        {date: '2020-03-23', time: ['09:00', '12:00']},
        {date: '2020-03-23', time: ['13:00', '17:00']},
        {date: '2020-03-24', time: ['09:00', '12:00']},
        {date: '2020-03-24', time: ['13:00', '17:00']},
        {date: '2020-03-25', time: ['09:00', '12:00']},
        {date: '2020-03-25', time: ['13:00', '17:00']},
        {date: '2020-03-26', time: ['09:00', '12:00']},
        {date: '2020-03-26', time: ['13:00', '17:00']},
      ],
    },
    {
      id: 2,
      name: '2021 台北國際汽機車零配件展 (AMPA)',
      date_start: '2021-04-14',
      date_end: '2021-04-17',
      address_name: '台北南港展覽館2館',
      address: '臺北市信義路五段五號',
      status: 'open',
      description:
        '由於國際車市對台需求強勁，且電動車、自駕車議題夯，外貿協會於4月14日南港展覽1館舉辦實體展「台北國際汽機車零配件展」及與台灣區電機電子工業同業公會共同主辦的「台北國際車用電子展」皆備受國際矚目，躍升成為全球車輛產業代表性展覽。本次AMPA實體展規模逾700攤位，共375家廠商參展，邀請各位打工族朋友們踴躍報名本次活動工作人員！',
      content:
        '由於國際車市對台需求強勁，且電動車、自駕車議題夯，外貿協會於4月14日南港展覽1館舉辦實體展「台北國際汽機車零配件展」及與台灣區電機電子工業同業公會共同主辦的「台北國際車用電子展」皆備受國際矚目，躍升成為全球車輛產業代表性展覽。本次AMPA實體展規模逾700攤位，共375家廠商參展，邀請各位打工族朋友們踴躍報名本次活動工作人員！content',
      image: 'https://picsum.photos/200/300',
      duration: [
        {date: '2020-04-14', time: ['09:00', '12:00']},
        {date: '2020-04-14', time: ['13:00', '17:00']},
        {date: '2020-04-15', time: ['09:00', '12:00']},
        {date: '2020-04-15', time: ['13:00', '17:00']},
        {date: '2020-04-16', time: ['09:00', '12:00']},
        {date: '2020-04-16', time: ['13:00', '17:00']},
        {date: '2020-04-17', time: ['09:00', '12:00']},
        {date: '2020-04-17', time: ['13:00', '17:00']},
      ],
    },
    {
      id: 3,
      name: '2021 台北國際禮品文具暨文創展',
      date_start: '2021-04-22',
      date_end: '2021-04-25',
      address_name: '台北南港展覽館2館',
      address: '臺北市信義路五段五號',
      status: 'end',
      description:
        '「台北國際禮品文具暨文創展」是台灣唯一禮品文創產業之專業國際展覽平台， 2021年4月22日至25日於台北世貿1館隆重舉辦！人才天下歡迎有意願者前來報名！',
      content:
        '「台北國際禮品文具暨文創展」是台灣唯一禮品文創產業之專業國際展覽平台， 2021年4月22日至25日於台北世貿1館隆重舉辦！人才天下歡迎有意願者前來報名！content',
      image: 'https://picsum.photos/id/237/200/300',
      duration: [
        {date: '2020-04-22', time: ['09:00', '12:00']},
        {date: '2020-04-22', time: ['13:00', '17:00']},
        {date: '2020-04-23', time: ['09:00', '12:00']},
        {date: '2020-04-23', time: ['13:00', '17:00']},
        {date: '2020-04-24', time: ['09:00', '12:00']},
        {date: '2020-04-24', time: ['13:00', '17:00']},
        {date: '2020-04-25', time: ['09:00', '12:00']},
        {date: '2020-04-25', time: ['13:00', '17:00']},
      ],
    },
  ],
  participantList: [
    {
      activity: '3',
      duration: [
        {date: '2020-04-22', time: ['09:00', '12:00'], state: 'applied'},
        {date: '2020-04-23', time: ['09:00', '12:00'], state: 'qualified'},
        {date: '2020-04-24', time: ['09:00', '12:00'], state: 'confirmed'},
        {date: '2020-04-25', time: ['09:00', '12:00'], state: 'unqualified'},
      ],
    },
  ],
};

const staff = {
  login: {
    refresh: 'refresh',
    token: 'token',
  },
  refresh: {},
  resetPassword: {},
  profile: {
    username: 'admin',
    name: 'admin',
    email: 'test@gmail.com',
    phone: '0900-000-000',
    phone_office: '02-2000-1000',
  },
  memberList: [
    {
      ...user.profile,
      owner: '1',
      publish: true,
      INTERVIEW_STATE: 'pass',
      updated: 1616144170,
    },
    {
      ...user.profile,
      owner: '2',
      name: '王查理',
      gender: true,
      id_number: 'A127108319',
      age: 16,
      publish: true,
      INTERVIEW_STATE: 'pending',
      updated: 1616144170,
    },
    {
      ...user.profile,
      owner: '3',
      name: 'Tony Stock',
      age: 18,
      gender: true,
      nationality: false,
      id_number: 'AA41995269',
      country: '新加坡',
      publish: true,
      INTERVIEW_STATE: 'sent',
      updated: 1616144170,
    },
    {
      ...user.profile,
      owner: '4',
      name: '田心愛',
      age: 15,
      nationality: false,
      id_number: 'A246269160',
      publish: true,
      INTERVIEW_STATE: 'confirmed',
      updated: 1616144170,
    },
    {
      ...user.profile,
      owner: '4',
      name: '蕭波筷',
      gender: true,
      age: 20,
      id_number: 'A171490466',
      publish: true,
      INTERVIEW_STATE: 'unqualified',
      updated: 1616144170,
    },
  ],
  memberDetail: {
    ...user.profile,
    owner: '3',
    name: '蕭波筷',
    gender: true,
    age: 20,
    id_number: 'A171490466',
    publish: true,
    INTERVIEW_STATE: 'pass',
    updated: 1616144170,
  },
  activityList: [
    ...user.activityList,
    {
      id: 3,
      name: '2021 台北國際花卉展',
      date_start: '2021-05-01',
      date_end: '2021-05-04',
      address_name: '台北南港展覽館2館',
      address: '臺北市信義路五段五號',
      status: 'draft',
      description:
        '「台北國際禮品文具暨文創展」是台灣唯一禮品文創產業之專業國際展覽平台， 2021年4月22日至25日於台北世貿1館隆重舉辦！人才天下歡迎有意願者前來報名！',
      content:
        '「台北國際禮品文具暨文創展」是台灣唯一禮品文創產業之專業國際展覽平台， 2021年4月22日至25日於台北世貿1館隆重舉辦！人才天下歡迎有意願者前來報名！content',
      image: 'https://picsum.photos/id/237/200/300',
      duration: [
        {date: '2020-05-01', time: ['09:00', '12:00']},
        {date: '2020-05-01', time: ['13:00', '17:00']},
        {date: '2020-05-02', time: ['09:00', '12:00']},
        {date: '2020-05-02', time: ['13:00', '17:00']},
        {date: '2020-05-03', time: ['09:00', '12:00']},
        {date: '2020-05-03', time: ['13:00', '17:00']},
        {date: '2020-05-04', time: ['09:00', '12:00']},
        {date: '2020-05-04', time: ['13:00', '17:00']},
      ],
    },
  ],
  activityDetail: {
    name: '2021 台北國際茶藝展',
    date_start: '2021-06-01',
    date_end: '2021-06-04',
    address_name: '台北南港展覽館2館',
    address: '臺北市信義路五段五號',
    status: 'draft',
    description:
      '「台北國際禮品文具暨文創展」是台灣唯一禮品文創產業之專業國際展覽平台， 2021年4月22日至25日於台北世貿1館隆重舉辦！人才天下歡迎有意願者前來報名！',
    content:
      '「台北國際禮品文具暨文創展」是台灣唯一禮品文創產業之專業國際展覽平台， 2021年4月22日至25日於台北世貿1館隆重舉辦！人才天下歡迎有意願者前來報名！content',
    image: 'https://picsum.photos/id/237/200/300',
    duration: [
      {date: '2020-06-01', time: ['09:00', '12:00']},
      {date: '2020-06-01', time: ['13:00', '17:00']},
      {date: '2020-06-02', time: ['09:00', '12:00']},
      {date: '2020-06-02', time: ['13:00', '17:00']},
      {date: '2020-06-03', time: ['09:00', '12:00']},
      {date: '2020-06-03', time: ['13:00', '17:00']},
      {date: '2020-06-04', time: ['09:00', '12:00']},
      {date: '2020-06-04', time: ['13:00', '17:00']},
    ],
  },
  projectList: [
    {
      duration: [
        {
          date: '2021-04-01',
          time: '',
          startTime: '12:00',
          endTime: '16:00',
        },
        {
          date: '2021-04-01',
          time: '',
          startTime: '16:00',
          endTime: '19:00',
        },
        {
          date: '2021-04-02',
          time: '',
          startTime: '12:00',
          endTime: '19:00',
        },
      ],
      name: '2021急診醫學會年會',
      budget: 500000,
      id: '2920601376',
      created: 1616144170,
      updated: 1618474657,
    },
  ],
  humanRuleVersions: [
    {
      version: 'v1',
      created: 1616144170 - 86400 * 3,
      updated: 1618474657 - 86400 * 2,
    },
    {
      version: 'v2',
      created: 1616144170 - 86400 * 2,
      updated: 1618474657 - 86400,
    },
    {version: 'v3', created: 1616144170 - 86400, updated: 1618474657},
  ],
  humanRuleRecords: [
    {
      id: '1',
      project: '2920601376',
      version: 'v1',
      name: 'test record 1',
    },
    {
      id: '2',
      project: '2920601376',
      version: 'v1',
      name: 'test record 2',
    },
    {
      id: '3',
      project: '2920601376',
      version: 'v2',
      name: 'test record 1',
    },
    {
      id: '4',
      project: '2920601376',
      version: 'v2',
      name: 'test record 2',
    },
    {
      id: '5',
      project: '2920601376',
      version: 'v3',
      name: 'test record 1',
    },
    {
      id: '6',
      project: '2920601376',
      version: 'v3',
      name: 'test record 2',
    },
  ],
};

module.exports = {
  user,
  staff,
};
